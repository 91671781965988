import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

const PlannerHeader = () => {
  const { t } = useTranslation()

  return (
    <Box flexGrow={1}>
      <Toolbar>
        <Typography variant='h6' color='inherit'>
          {t('Planner.Title')}
        </Typography>
      </Toolbar>
    </Box>
  )
}

export default PlannerHeader
