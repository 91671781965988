import { pipe } from 'fp-ts/lib/function'
import { fold } from 'fp-ts/lib/Either'
import { Error } from '@appointment-planner/api/client/interfaces'
import Service from '@appointment-planner/api/services/abstract-service'
import { Appointment } from './models'

export interface IAppointmentsService {
  post(appointment: Appointment): Promise<Error | null>
}

export class AppointmentsService extends Service implements IAppointmentsService {
  protected path = '/proposals'

  async post(appointment: Appointment): Promise<Error | null> {
    const body = JSON.stringify(appointment)

    const result = await this.client.doRequest(this.path, { method: 'POST', body })

    return pipe(
      result,
      fold(
        e => e,
        () => null
      )
    )
  }
}
