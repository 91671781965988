import { Vehicle } from './models'
import Service from '@appointment-planner/api/services/abstract-service'

export interface IVehiclesService {
  get(licensePlate: string): Promise<Vehicle | null>
}

export class VehiclesService extends Service implements IVehiclesService {
  protected path = '/vehicles/registration'

  async get(licensePlate: string): Promise<Vehicle | null> {
    const response = await this.client.doRequest(`${this.path}/${licensePlate}`)

    return this.decodeResponseTo(response, Vehicle)
  }
}
