import Cancel from '@mui/icons-material/Cancel'
import { Zoom } from '@mui/material'

interface Props {
  showIcon: boolean
}

const CancelIcon = ({ showIcon }: Props) => {
  return (
    <Zoom in={showIcon}>
      <Cancel data-testid='invalid-registration-icon' sx={{ color: 'error.main' }} />
    </Zoom>
  )
}

CancelIcon.defaultProps = {
  showIcon: true
}

export default CancelIcon
