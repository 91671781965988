import React from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import Warning from '@mui/icons-material/Warning'
import { useTranslation } from 'react-i18next'

const NoAvailabilityMessage: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Paper data-testid='warning-message-no-availability' sx={{ p: 2 }}>
      <Grid container direction='row' alignItems='center'>
        <Grid item>
          <Warning sx={{ color: 'warning.main', fontSize: 48, marginRight: 2 }} />
        </Grid>
        <Grid item xs={9}>
          <Typography variant='h5'>{t('Planner.Errors.NoAvailability.Title')}</Typography>
          <Typography variant='body1'>{t('Planner.Errors.NoAvailability.Message')}</Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default NoAvailabilityMessage
