import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import Warning from '@mui/icons-material/Warning'
import { useConfig } from '@appointment-planner/config-provider'

const ConfigErrorMessage: React.FC = () => {
  const { t } = useTranslation()
  const { Locale, ShopID } = useConfig()
  return (
    <Dialog open={ShopID === '' || Locale === ''} PaperProps={{ sx: { maxWidth: 640 } }} data-testid='planner-config-error'>
      <DialogTitle>
        <Box component='span' sx={{ alignItems: 'center', display: 'flex' }}>
          <Warning sx={{ color: 'warning.main', fontSize: 30, mr: 1 }} />
          {t('Planner.ConfigError.Title')}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t('Planner.ConfigError.Message', { ShopID, Locale })}</DialogContentText>
        <DialogContentText
          sx={{
            backgroundColor: 'grey.900',
            borderRadius: 1,
            color: theme => theme.palette.getContrastText(theme.palette.grey[900]),
            fontSize: theme => theme.typography.fontSize,
            fontFamily: 'monospace',
            p: 1
          }}
        >
          <code>{t('Planner.ConfigError.Example', { location: window.location.origin })}</code>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default ConfigErrorMessage
