import React from 'react'
import { useFormikContext } from 'formik'
import Step4 from '@appointment-planner/components/vertical/Step4'
import { FormSchema } from '@appointment-planner/form'
import { StepContentProps } from '@appointment-planner/views/VerticalPlanner'
import { useTranslation } from 'react-i18next'
import { Activity } from '@appointment-planner/activities/models'
import { Vehicle } from '@appointment-planner/vehicles/models'
import { useLuxonFormatter } from '@appointment-planner/helpers/useFormat'

type Props = Pick<StepContentProps, 'vehicleInfo' | 'activities'>

const Step4Container: React.FC<Props> = ({ vehicleInfo, activities }) => {
  const { values } = useFormikContext<FormSchema>()
  const { t } = useTranslation()
  const formatDate = useLuxonFormatter('date')
  const formatTime = useLuxonFormatter('time')

  const formatSummaryInformation = (values: FormSchema, activities: Activity[] | null, vehicleInfo: Vehicle | null) => {
    return {
      activityRemark: values.ActivityRemark,
      address: addressFormatter(values),
      city: values.City,
      emailAddress: values.EmailAddress,
      firstName: values.FirstName,
      name: values.Name,
      odometer: odometerFormatter(values.Odometer),
      phoneNumber: values.PhoneNumber,
      postalCode: values.PostalCode,
      proposalDate: (values.Date && formatDate(values.Date)) || '',
      proposalTime: (values.Time && formatTime(values.Time)) || '',
      registration: values.Registration,
      remark: values.Remark,
      selectedActivityCodes: activitiesFormatter(values.ActivityUUIDs, activities),
      vehicleBrand: (vehicleInfo && vehicleInfo.Brand) || '',
      vehicleModel: (vehicleInfo && vehicleInfo.Model) || '',
      vehicleMOTExpiryDate: (vehicleInfo && formatDate(vehicleInfo.MOTExpiryDate)) || '',
      courtesyVehicle: values.CourtesyVehicle ? t('Planner.Fields.CourtesyVehicle.Yes') : t('Planner.Fields.CourtesyVehicle.No')
    }
  }

  const activitiesFormatter = (selectedActivityUUIDs: string[], activities: Activity[] | null) => {
    const selectedActivities = activities && activities.filter(item => selectedActivityUUIDs.includes(item.UUID))
    return (selectedActivities && selectedActivities.map(item => item.Description).join(', ')) || ''
  }

  const addressFormatter = (values: FormSchema) => {
    return `${values.Street} ${values.HouseNumber} ${values.HouseNumberAddition}`
  }

  const odometerFormatter = (odometerValue: number) => {
    return `${odometerValue} ${t('Planner.Fields.Odometer.Unit', { context: values.OdometerUnit })}`
  }

  return <Step4 {...formatSummaryInformation(values, activities, vehicleInfo)} />
}

export default Step4Container
