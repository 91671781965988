import * as t from 'io-ts'
import { pipe } from 'fp-ts/lib/function'
import { getOrElse } from 'fp-ts/lib/Either'

import { CountryCode } from '@carsys/enums/country-code'

import { ShopID } from './shop-id'

export const CountryCodeRTT = t.keyof<{ [index in CountryCode]: null }>(
  {
    [CountryCode.AD]: null,
    [CountryCode.AE]: null,
    [CountryCode.AF]: null,
    [CountryCode.AG]: null,
    [CountryCode.AI]: null,
    [CountryCode.AL]: null,
    [CountryCode.AM]: null,
    [CountryCode.AO]: null,
    [CountryCode.AQ]: null,
    [CountryCode.AR]: null,
    [CountryCode.AS]: null,
    [CountryCode.AT]: null,
    [CountryCode.AU]: null,
    [CountryCode.AW]: null,
    [CountryCode.AX]: null,
    [CountryCode.AZ]: null,
    [CountryCode.BA]: null,
    [CountryCode.BB]: null,
    [CountryCode.BD]: null,
    [CountryCode.BE]: null,
    [CountryCode.BF]: null,
    [CountryCode.BG]: null,
    [CountryCode.BH]: null,
    [CountryCode.BI]: null,
    [CountryCode.BJ]: null,
    [CountryCode.BL]: null,
    [CountryCode.BM]: null,
    [CountryCode.BN]: null,
    [CountryCode.BO]: null,
    [CountryCode.BQ]: null,
    [CountryCode.BR]: null,
    [CountryCode.BS]: null,
    [CountryCode.BT]: null,
    [CountryCode.BV]: null,
    [CountryCode.BW]: null,
    [CountryCode.BY]: null,
    [CountryCode.BZ]: null,
    [CountryCode.CA]: null,
    [CountryCode.CC]: null,
    [CountryCode.CD]: null,
    [CountryCode.CF]: null,
    [CountryCode.CG]: null,
    [CountryCode.CH]: null,
    [CountryCode.CI]: null,
    [CountryCode.CK]: null,
    [CountryCode.CL]: null,
    [CountryCode.CM]: null,
    [CountryCode.CN]: null,
    [CountryCode.CO]: null,
    [CountryCode.CR]: null,
    [CountryCode.CU]: null,
    [CountryCode.CV]: null,
    [CountryCode.CW]: null,
    [CountryCode.CX]: null,
    [CountryCode.CY]: null,
    [CountryCode.CZ]: null,
    [CountryCode.DE]: null,
    [CountryCode.DJ]: null,
    [CountryCode.DK]: null,
    [CountryCode.DM]: null,
    [CountryCode.DO]: null,
    [CountryCode.DZ]: null,
    [CountryCode.EC]: null,
    [CountryCode.EE]: null,
    [CountryCode.EG]: null,
    [CountryCode.EH]: null,
    [CountryCode.ER]: null,
    [CountryCode.ES]: null,
    [CountryCode.ET]: null,
    [CountryCode.FI]: null,
    [CountryCode.FJ]: null,
    [CountryCode.FK]: null,
    [CountryCode.FM]: null,
    [CountryCode.FO]: null,
    [CountryCode.FR]: null,
    [CountryCode.GA]: null,
    [CountryCode.GB]: null,
    [CountryCode.GD]: null,
    [CountryCode.GE]: null,
    [CountryCode.GF]: null,
    [CountryCode.GG]: null,
    [CountryCode.GH]: null,
    [CountryCode.GI]: null,
    [CountryCode.GL]: null,
    [CountryCode.GM]: null,
    [CountryCode.GN]: null,
    [CountryCode.GP]: null,
    [CountryCode.GQ]: null,
    [CountryCode.GR]: null,
    [CountryCode.GS]: null,
    [CountryCode.GT]: null,
    [CountryCode.GU]: null,
    [CountryCode.GW]: null,
    [CountryCode.GY]: null,
    [CountryCode.HK]: null,
    [CountryCode.HM]: null,
    [CountryCode.HN]: null,
    [CountryCode.HR]: null,
    [CountryCode.HT]: null,
    [CountryCode.HU]: null,
    [CountryCode.ID]: null,
    [CountryCode.IE]: null,
    [CountryCode.IL]: null,
    [CountryCode.IM]: null,
    [CountryCode.IN]: null,
    [CountryCode.IO]: null,
    [CountryCode.IQ]: null,
    [CountryCode.IR]: null,
    [CountryCode.IS]: null,
    [CountryCode.IT]: null,
    [CountryCode.JE]: null,
    [CountryCode.JM]: null,
    [CountryCode.JO]: null,
    [CountryCode.JP]: null,
    [CountryCode.KE]: null,
    [CountryCode.KG]: null,
    [CountryCode.KH]: null,
    [CountryCode.KI]: null,
    [CountryCode.KM]: null,
    [CountryCode.KN]: null,
    [CountryCode.KP]: null,
    [CountryCode.KR]: null,
    [CountryCode.KW]: null,
    [CountryCode.KY]: null,
    [CountryCode.KZ]: null,
    [CountryCode.LA]: null,
    [CountryCode.LB]: null,
    [CountryCode.LC]: null,
    [CountryCode.LI]: null,
    [CountryCode.LK]: null,
    [CountryCode.LR]: null,
    [CountryCode.LS]: null,
    [CountryCode.LT]: null,
    [CountryCode.LU]: null,
    [CountryCode.LV]: null,
    [CountryCode.LY]: null,
    [CountryCode.MA]: null,
    [CountryCode.MC]: null,
    [CountryCode.MD]: null,
    [CountryCode.ME]: null,
    [CountryCode.MF]: null,
    [CountryCode.MG]: null,
    [CountryCode.MH]: null,
    [CountryCode.MK]: null,
    [CountryCode.ML]: null,
    [CountryCode.MM]: null,
    [CountryCode.MN]: null,
    [CountryCode.MO]: null,
    [CountryCode.MP]: null,
    [CountryCode.MQ]: null,
    [CountryCode.MR]: null,
    [CountryCode.MS]: null,
    [CountryCode.MT]: null,
    [CountryCode.MU]: null,
    [CountryCode.MV]: null,
    [CountryCode.MW]: null,
    [CountryCode.MX]: null,
    [CountryCode.MY]: null,
    [CountryCode.MZ]: null,
    [CountryCode.NA]: null,
    [CountryCode.NC]: null,
    [CountryCode.NE]: null,
    [CountryCode.NF]: null,
    [CountryCode.NG]: null,
    [CountryCode.NI]: null,
    [CountryCode.NL]: null,
    [CountryCode.NO]: null,
    [CountryCode.NP]: null,
    [CountryCode.NR]: null,
    [CountryCode.NU]: null,
    [CountryCode.NZ]: null,
    [CountryCode.OM]: null,
    [CountryCode.PA]: null,
    [CountryCode.PE]: null,
    [CountryCode.PF]: null,
    [CountryCode.PG]: null,
    [CountryCode.PH]: null,
    [CountryCode.PK]: null,
    [CountryCode.PL]: null,
    [CountryCode.PM]: null,
    [CountryCode.PN]: null,
    [CountryCode.PR]: null,
    [CountryCode.PS]: null,
    [CountryCode.PT]: null,
    [CountryCode.PW]: null,
    [CountryCode.PY]: null,
    [CountryCode.QA]: null,
    [CountryCode.RE]: null,
    [CountryCode.RO]: null,
    [CountryCode.RS]: null,
    [CountryCode.RU]: null,
    [CountryCode.RW]: null,
    [CountryCode.SA]: null,
    [CountryCode.SB]: null,
    [CountryCode.SC]: null,
    [CountryCode.SD]: null,
    [CountryCode.SE]: null,
    [CountryCode.SG]: null,
    [CountryCode.SH]: null,
    [CountryCode.SI]: null,
    [CountryCode.SJ]: null,
    [CountryCode.SK]: null,
    [CountryCode.SL]: null,
    [CountryCode.SM]: null,
    [CountryCode.SN]: null,
    [CountryCode.SO]: null,
    [CountryCode.SR]: null,
    [CountryCode.SS]: null,
    [CountryCode.ST]: null,
    [CountryCode.SV]: null,
    [CountryCode.SX]: null,
    [CountryCode.SY]: null,
    [CountryCode.SZ]: null,
    [CountryCode.TC]: null,
    [CountryCode.TD]: null,
    [CountryCode.TF]: null,
    [CountryCode.TG]: null,
    [CountryCode.TH]: null,
    [CountryCode.TJ]: null,
    [CountryCode.TK]: null,
    [CountryCode.TL]: null,
    [CountryCode.TM]: null,
    [CountryCode.TN]: null,
    [CountryCode.TO]: null,
    [CountryCode.TR]: null,
    [CountryCode.TT]: null,
    [CountryCode.TV]: null,
    [CountryCode.TW]: null,
    [CountryCode.TZ]: null,
    [CountryCode.UA]: null,
    [CountryCode.UG]: null,
    [CountryCode.UM]: null,
    [CountryCode.US]: null,
    [CountryCode.UY]: null,
    [CountryCode.UZ]: null,
    [CountryCode.VA]: null,
    [CountryCode.VC]: null,
    [CountryCode.VE]: null,
    [CountryCode.VG]: null,
    [CountryCode.VI]: null,
    [CountryCode.VN]: null,
    [CountryCode.VU]: null,
    [CountryCode.WF]: null,
    [CountryCode.WS]: null,
    [CountryCode.XK]: null,
    [CountryCode.YE]: null,
    [CountryCode.YT]: null,
    [CountryCode.ZA]: null,
    [CountryCode.ZM]: null,
    [CountryCode.ZW]: null,
    [CountryCode.ZZ]: null
  },
  'CountryCode'
)

export const countryCode = (s: unknown) => {
  return pipe(
    CountryCodeRTT.decode(s),
    getOrElse(() => '' as CountryCode)
  )
}

export const countryCodeFromShopID = (shopID: ShopID) => {
  return pipe(shopID, (shopID: ShopID) => shopID.substring(0, 2), countryCode)
}
