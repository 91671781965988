import React from 'react'
import { Vehicle } from '@appointment-planner/vehicles/models'
import { Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLuxonFormatter } from '@appointment-planner/helpers/useFormat'

interface Props {
  vehicleInfo: Vehicle | null
}

const VehicleInformation: React.FC<Props> = ({ vehicleInfo }) => {
  const { t } = useTranslation()
  const formatDate = useLuxonFormatter('date')

  if (!vehicleInfo) {
    return null
  }

  return (
    <Paper sx={{ px: 2, py: 3 }}>
      {vehicleInfo.Brand && vehicleInfo.Model && (
        <Typography variant='h5' data-testid='vehicle-brand-and-model'>{`${vehicleInfo.Brand} ${vehicleInfo.Model}`}</Typography>
      )}
      {vehicleInfo.MOTExpiryDate && (
        <Typography data-testid='vehicle-mot-expiry-date'>
          {t('Planner.MOTExpiryDate')}: {formatDate(vehicleInfo.MOTExpiryDate)}
        </Typography>
      )}
      {vehicleInfo.Type && <Typography data-testid='vehicle-type'>{vehicleInfo.Type}</Typography>}
    </Paper>
  )
}

export default VehicleInformation
