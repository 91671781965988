import { AppointmentTimesList } from './models'
import Service from '@appointment-planner/api/services/abstract-service'
import { ShopID } from '@appointment-planner/api/types'

export interface IAppointmentTimesService {
  get(shopID: ShopID): Promise<AppointmentTimesList | null>
}
export class AppointmentTimesService extends Service implements IAppointmentTimesService {
  protected path = '/appointment-times'

  async get(shopID: ShopID): Promise<AppointmentTimesList | null> {
    const response = await this.client.doRequest(this.path, { params: { ShopID: shopID } })

    return this.decodeResponseTo(response, AppointmentTimesList)
  }
}
