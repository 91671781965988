import React from 'react'
import { useField } from 'formik'
import { useDebouncedCallback } from 'use-debounce'

import Step1 from '@appointment-planner/components/vertical/Step1'
import { useService } from '@appointment-planner/service-provider'
import { StepContentProps } from '@appointment-planner/views/VerticalPlanner'
import { ActionTypes } from '@appointment-planner/state/applicationReducer'
import { OdometerType } from '@carsys/enums/odometer-type'

const Step1Container: React.FC<Pick<StepContentProps, 'vehicleInfo' | 'dispatch'>> = ({ vehicleInfo, dispatch }) => {
  const [licenseplate, licenseplateMeta] = useField('Registration')
  const [odometer, odometerMeta] = useField<number>('Odometer')
  const [odometerUnit] = useField<OdometerType>('OdometerUnit')

  const isMountedRef = React.useRef(false)

  React.useEffect(() => {
    isMountedRef.current = true

    return () => {
      isMountedRef.current = false
    }
  }, [])

  const vehicles = useService('vehicles')

  const lookupVehicle = useDebouncedCallback((value: string) => {
    if (!value.length) {
      isMountedRef.current && dispatch({ type: ActionTypes.SetVehicleInfo, payload: null })
      return
    }

    isMountedRef.current && dispatch({ type: ActionTypes.SetVehicleInfo, payload: null })

    vehicles.get(value).then(result => {
      if (!result) return

      if (isMountedRef.current) {
        dispatch({ type: ActionTypes.SetVehicleInfo, payload: result })

        licenseplate.onChange('Registration')(result.Registration)
      }
    })
  }, 600)

  const enhancedLicenseplateOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    licenseplate.onChange(e)
    lookupVehicle(e.target.value)
  }

  return (
    <Step1
      validLicenseplate={Boolean(vehicleInfo && licenseplate.value.length > 0)}
      licenseplateFormField={{
        ...licenseplate,
        onChange: enhancedLicenseplateOnChange,
        error: licenseplateMeta.error,
        touched: licenseplateMeta.touched
      }}
      odometerFormField={{
        ...odometer,
        error: odometerMeta.error,
        touched: odometerMeta.touched,
        odometerUnit: odometerUnit.value
      }}
      vehicleInfo={vehicleInfo}
    />
  )
}

export default Step1Container
