import React from 'react'
import { Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'

import { Activity as ActivityModel } from '@appointment-planner/activities/models'
import { UUID } from '@appointment-planner/api/types'

export interface Activity extends Pick<ActivityModel, 'ShopID' | 'Description' | 'UUID'> {
  checked: boolean
}

interface Props {
  onClick: (uuid: UUID) => void
  activity: Activity
}

export const ActivityCard: React.FC<Props> = ({ activity, onClick }) => {
  return (
    <ListItem component='li' disablePadding data-testid='activity-checkbox'>
      <ListItemButton onClick={() => onClick(activity.UUID)}>
        <ListItemIcon>
          <Checkbox name='ActivityUUIDs' checked={activity.checked} edge='start' disableRipple value={activity.checked} />
        </ListItemIcon>
        <ListItemText>{activity.Description}</ListItemText>
      </ListItemButton>
    </ListItem>
  )
}

export default ActivityCard
