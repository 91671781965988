import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormikHelpers, FormikProvider, useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import * as yup from 'yup'

import { OdometerType } from '@carsys/enums/odometer-type'
import { ProposalSource } from '@cbgms/api/modules/enums/proposal-source'
import { FormSchema, createValidationSchema } from '@appointment-planner/form'
import { useConfig } from '@appointment-planner/config-provider'
import { useService } from '@appointment-planner/service-provider'
import { ActionTypes, State, applicationReducer, defaultState } from '@appointment-planner/state/applicationReducer'
import VerticalPlanner from '@appointment-planner/views/VerticalPlanner'
import { Appointment } from '@appointment-planner/appointments/models'
import { CountryCode } from '@carsys/enums/country-code'

interface Props {
  defaultState: State
  steps: string[]
}

const PlannerContainer = ({ defaultState, steps }: Props) => {
  const [state, dispatch] = React.useReducer(applicationReducer, defaultState)
  const appointmentService = useService('appointments')
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [showCourtesyVehicle, setShowCourtesyVehicle] = useState(false)

  const onSubmit = React.useCallback(
    async (values: FormSchema, { setSubmitting }: FormikHelpers<FormSchema>) => {
      if (Appointment.is(values)) {
        const result = await appointmentService.post(values)

        if (!result) {
          setSubmitting(false)
          dispatch({ type: ActionTypes.NextStep })
          return
        }
      }

      setSubmitting(false)
      enqueueSnackbar(t('Planner.Errors.FailedToPostProposal'), { variant: 'error' })
    },
    [appointmentService, dispatch, enqueueSnackbar, t]
  )

  const { ShopCountryCode, ShopID } = useConfig()

  const shopDetailsService = useService('shopDetails')

  useEffect(() => {
    ;(async () => {
      const shopDetails = await shopDetailsService.get(ShopID)
      if (shopDetails) {
        setShowCourtesyVehicle(shopDetails.ModuleCourtesyVehiclesEnabled)
      }
    })()
  }, [])

  const validationSchema = {}

  const validations = React.useMemo(
    () => createValidationSchema(state.availability, showCourtesyVehicle),
    [state.availability, showCourtesyVehicle]
  )

  validations.forEach(stepSchema => Object.assign(validationSchema, stepSchema))

  const OdometerUnit: OdometerType = ShopCountryCode === CountryCode.GB ? OdometerType.Miles : OdometerType.Kilometers

  const formik = useFormik<FormSchema>({
    initialValues: {
      ShopID,
      // the source should be set to planner for each request that's done from this application
      // in the future this will probably be replaced by some sort of API token which contains this source
      Source: ProposalSource.Planner,
      RegistrationCountry: ShopCountryCode,
      Registration: '',
      Odometer: 0,
      OdometerUnit,
      ActivityRemark: '',
      ActivityUUIDs: [],
      Title: '',
      FirstName: '',
      NamePrefix: '',
      Name: '',
      EmailAddress: '',
      PhoneNumber: '',
      Street: '',
      HouseNumber: '',
      HouseNumberAddition: '',
      PostalCode: '',
      City: '',
      Remark: '',
      Date: null,
      Time: '',
      CourtesyVehicle: null
    },
    validationSchema: yup.object().shape(validationSchema),
    onSubmit
  })

  const handleReset = () => {
    formik.resetForm()
    dispatch({ type: ActionTypes.ClearState })
  }

  const schemaInCurrentStep = yup.object(validations[state.step])
  const isStepValid = schemaInCurrentStep.isValidSync(formik.values)

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <VerticalPlanner
          activeStep={state.step}
          activities={state.activities}
          appointmentTimes={state.appointmentTimes}
          availability={state.availability}
          dispatch={dispatch}
          disableNextButton={!isStepValid}
          handleReset={handleReset}
          handleSubmit={formik.submitForm}
          isSubmitting={formik.isSubmitting}
          steps={steps}
          vehicleInfo={state.vehicleInfo}
        />
      </form>
    </FormikProvider>
  )
}

PlannerContainer.defaultProps = {
  defaultState,
  steps: [
    'Planner.VehicleInformation.Title',
    'Planner.ServiceInformation.Title',
    'Planner.PersonalInformation.Title',
    'Planner.SummaryInformation.Title'
  ]
}

export default PlannerContainer
