import { HTTPClient } from '@appointment-planner/api/client/interfaces'
import { ActivitiesService, IActivitiesService } from '@appointment-planner/activities/service'
import { AppointmentsService, IAppointmentsService } from '@appointment-planner/appointments/service'
import { AppointmentTimesService, IAppointmentTimesService } from '@appointment-planner/appointment-times/service'
import { AvailabilityService, IAvailabilityService } from '@appointment-planner/availability/service'
import { IVehiclesService, VehiclesService } from '@appointment-planner/vehicles/service'
import { IShopDetailsService, ShopDetailsService } from '../shop-details/service'

export interface Services {
  activities: IActivitiesService
  appointments: IAppointmentsService
  appointmentTimes: IAppointmentTimesService
  availability: IAvailabilityService
  vehicles: IVehiclesService
  shopDetails: IShopDetailsService
}

export const createServices = (client: HTTPClient): Services => ({
  activities: new ActivitiesService(client),
  appointments: new AppointmentsService(client),
  appointmentTimes: new AppointmentTimesService(client),
  availability: new AvailabilityService(client),
  vehicles: new VehiclesService(client),
  shopDetails: new ShopDetailsService(client)
})
