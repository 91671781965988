import * as t from 'io-ts'
import { ShopID, UUID } from '@appointment-planner/api/types'

export const Activity = t.interface(
  {
    Description: t.string,
    ShopID,
    UUID
  },
  'Activity'
)

export type Activity = t.TypeOf<typeof Activity>

export const ActivitiesList = t.interface(
  {
    Activities: t.array(Activity)
  },
  'ActivitiesList'
)

export type ActivitiesList = t.TypeOf<typeof ActivitiesList>
