import * as t from 'io-ts'
import { pipe } from 'fp-ts/lib/function'
import { getOrElse } from 'fp-ts/lib/Either'

interface UUIDBrand {
  readonly UUID: unique symbol
}

export const UUIDRegEx = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i

export const UUID = t.brand(
  t.string,
  (s: string): s is t.Branded<string, UUIDBrand> => {
    return UUIDRegEx.test(s)
  },
  'UUID'
)

export const uuid = (s: unknown) => {
  return pipe(
    UUID.decode(s),
    getOrElse(() => '00000000-0000-0000-0000-000000000000' as UUID)
  )
}

export type UUID = t.TypeOf<typeof UUID>
