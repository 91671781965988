import { render } from 'react-dom'
import * as Sentry from '@sentry/browser'

import { AppInitProvider } from './AppInit'
import PlannerContainer from './containers/PlannerContainer'

if (__PRODUCTION__) {
  Sentry.init({
    dsn: 'https://166a053777c44959ab2ac07e694fe61a@sentry.io/1775751',
    environment: window.location.hostname,
    ignoreErrors: [/Non-Error promise rejection captured/]
  })
}

const app = document.getElementById('appointment-planner')

render(
  <AppInitProvider>
    <PlannerContainer />
  </AppInitProvider>,
  app
)
