import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Paper, Typography } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'

interface Props {
  handleStepReset: () => void
}

const FinalStep: React.FC<Props> = ({ handleStepReset }) => {
  const { t } = useTranslation()
  return (
    <Grid container justifyContent='center' spacing={2}>
      <Grid item xs={12} sm={6}>
        <Paper data-testid='proposal-success-message' sx={{ p: 2, textAlign: 'center' }}>
          <SendIcon sx={{ color: 'primary.main', fontSize: 72 }} />
          <Typography variant='h6' component='h2'>
            {t('Planner.Proposal.SuccessTitle')}
          </Typography>
          <Typography>{t('Planner.Proposal.SuccessText')}</Typography>
        </Paper>
      </Grid>
      <Grid container item justifyContent='center'>
        <Grid item>
          <Button onClick={handleStepReset}>{t('Planner.Buttons.Reset')}</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FinalStep
