import { Field, FieldProps } from 'formik'
import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ActivityRemark = () => {
  const { t } = useTranslation()
  return (
    <Field name='ActivityRemark'>
      {({ field, meta }: FieldProps<string>) => (
        <TextField
          {...field}
          multiline
          fullWidth
          variant='outlined'
          helperText={t('Planner.Fields.ActivityRemark.Helper')}
          label={(meta.touched && meta.error) || t('Planner.Fields.ActivityRemark.Label')}
          id='activity-remark'
          error={Boolean(meta.touched && meta.error)}
          inputProps={{ maxLength: 500 }}
        />
      )}
    </Field>
  )
}

export default ActivityRemark
