import { constant, identity, pipe } from 'fp-ts/lib/function'
import { chainNullableK, fold, fromNullable, getOrElse, map } from 'fp-ts/lib/Option'
import { DateTime } from 'luxon'

import { ShopAvailability } from '@appointment-planner/availability/models'
import { Availability, Day } from '@appointment-planner/state/applicationReducer'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers'

const mapToISODate = (date: DateTime) => {
  return pipe(
    fromNullable(date),
    map(date => date.toISODate()),
    getOrElse(constant(''))
  )
}

const mapToDateOrUndefined = (date?: string) => {
  return pipe(
    fromNullable(date),
    map(date => DateTime.fromISO(date)),
    fold(constant(undefined), identity)
  )
}

const isDisabled = (day: Day) => day.availability === ShopAvailability.Closed || day.availability === ShopAvailability.Low

const createCustomPickerDayProps = (availability: Availability | null) => {
  const availabilityOnDate = (date: DateTime) => {
    return pipe(
      fromNullable(availability),
      map(availability => availability.days),
      chainNullableK(days => days[mapToISODate(date)]),
      getOrElse<Day>(() => ({ availability: ShopAvailability.Closed, color: '' }))
    )
  }

  return {
    disablePast: true,
    minDate: mapToDateOrUndefined(availability?.minDate),
    maxDate: mapToDateOrUndefined(availability?.maxDate),
    shouldDisableDate: (day: DateTime) => {
      const date = availabilityOnDate(day)

      return isDisabled(date)
    },
    renderDay: (day: DateTime, selectedDates: DateTime[], pickersDayProps: PickersDayProps<DateTime>) => {
      const [selectedDate] = selectedDates
      const date = availabilityOnDate(day)

      const selected = selectedDate ? day.equals(selectedDate) : false
      const disabled = isDisabled(date)

      return (
        <PickersDay
          data-testid={`availability-datepicker-day-${date.availability}`}
          sx={{ backgroundColor: date.color }}
          selected={selected}
          disabled={disabled}
          {...pickersDayProps}
        />
      )
    }
  }
}

export default createCustomPickerDayProps
