import * as t from 'io-ts'
import { ShopID, UUID } from '@appointment-planner/api/types'

export enum ShopAvailability {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  Closed = 'closed'
}

const ShopAvailabilityRTT = t.keyof<{ [index in ShopAvailability]: null }>(
  {
    [ShopAvailability.High]: null,
    [ShopAvailability.Medium]: null,
    [ShopAvailability.Low]: null,
    [ShopAvailability.Closed]: null
  },
  'ShopAvailability'
)

const Capacity = t.interface(
  {
    Availability: ShopAvailabilityRTT,
    AvailabilityColor: t.string
  },
  'Capacity'
)

export type Capacity = t.TypeOf<typeof Capacity>

const CapacityPerActivity = t.intersection(
  [
    t.interface({
      ActivityUUID: UUID
    }),
    Capacity
  ],
  'CapacityPerActivity'
)

type CapacityPerActivity = t.TypeOf<typeof CapacityPerActivity>

const AvailabilityPerDay = t.interface(
  {
    Date: t.string,
    LowestCapacity: Capacity,
    Activities: t.array(CapacityPerActivity)
  },
  'AvailabilityPerDay'
)

export type AvailabilityPerDay = t.TypeOf<typeof AvailabilityPerDay>

const AvailabilityPerShop = t.interface(
  {
    Days: t.array(AvailabilityPerDay),
    ShopID
  },
  'AvailabilityPerShop'
)

type AvailabilityPerShop = t.TypeOf<typeof AvailabilityPerShop>

export const AvailabilityList = t.interface(
  {
    Shops: t.array(AvailabilityPerShop)
  },
  'AvailabilityList'
)

export type AvailabilityList = t.TypeOf<typeof AvailabilityList>
