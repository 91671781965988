import * as t from 'io-ts'
import { pipe } from 'fp-ts/lib/function'
import { Either, chain, getOrElse, isLeft, map } from 'fp-ts/lib/Either'
import { PathReporter } from 'io-ts/lib/PathReporter'
import { Error, HTTPClient } from '@appointment-planner/api/client/interfaces'
import { ShopID } from '@appointment-planner/api/types'

export default abstract class Service {
  protected abstract path: string

  constructor(protected client: HTTPClient) {}

  private isDecodingError(e: Either<any, any>): e is Either<t.Errors, any> {
    return isLeft(e) && e.left.type === undefined
  }

  protected decodeResponseTo<C extends t.Mixed>(response: Either<Error, unknown>, model: C) {
    const Response = t.interface(
      {
        Data: model
      },
      'Response'
    )

    return pipe(
      response,
      chain<t.Errors | Error, unknown, t.TypeOf<typeof Response>>(Response.decode),
      either => {
        if (this.isDecodingError(either)) {
          console.error(`Failed to decode API response to ${model.name} model\n${PathReporter.report(either).join('\n')}`)
        }

        return either
      },
      map(response => response.Data),
      getOrElse<t.Errors | Error, t.TypeOf<C> | null>(() => null)
    )
  }

  protected stringifyShopIDs(shopIDs: ShopID | ShopID[]): string {
    return Array.isArray(shopIDs) ? shopIDs.join(',') : shopIDs
  }
}
