import { chain, fold, map } from 'fp-ts/lib/Option'
import { head, last, reduce } from 'fp-ts/lib/Array'
import { constant, pipe } from 'fp-ts/lib/function'

import { Activity } from '@appointment-planner/activities/models'
import { AvailabilityList, ShopAvailability } from '@appointment-planner/availability/models'
import { AppointmentTimesList } from '@appointment-planner/appointment-times/models'
import { Vehicle } from '@appointment-planner/vehicles/models'

export enum ActionTypes {
  ClearState,
  GoToStep,
  NextStep,
  PreviousStep,
  SetActivities,
  SetAppointmentTimes,
  SetAvailability,
  SetSettings,
  SetVehicleInfo
}

interface SetVehicleInfo {
  type: ActionTypes.SetVehicleInfo
  payload: Vehicle | null
}

interface SetActivities {
  type: ActionTypes.SetActivities
  payload: Activity[] | null
}

interface SetAvailability {
  type: ActionTypes.SetAvailability
  payload: AvailabilityList | null
}

interface SetAppointmentTimes {
  type: ActionTypes.SetAppointmentTimes
  payload: AppointmentTimesList | null
}

interface ClearState {
  type: ActionTypes.ClearState
}

interface NextStep {
  type: ActionTypes.NextStep
}

interface PreviousStep {
  type: ActionTypes.PreviousStep
}

interface GoToStep {
  type: ActionTypes.GoToStep
  payload: 0 | 1 | 2 | 3 | 4
}

export type Actions =
  | SetVehicleInfo
  | SetActivities
  | SetAppointmentTimes
  | SetAvailability
  | ClearState
  | NextStep
  | PreviousStep
  | GoToStep

export interface Day {
  availability: ShopAvailability
  color: string
}

export interface Availability {
  days: {
    [index: string]: Day
  }
  maxDate: string
  minDate: string
}

export interface AppointmentTimes {
  [index: string]: string[]
}

export interface State {
  activities: Activity[] | null
  appointmentTimes: AppointmentTimes | null
  availability: Availability | null
  step: number
  vehicleInfo: Vehicle | null
}

export const defaultState: State = {
  activities: null,
  appointmentTimes: null,
  availability: null,
  step: 0,
  vehicleInfo: null
}

export const applicationReducer: React.Reducer<State, Actions> = (state, action) => {
  switch (action.type) {
    case ActionTypes.SetActivities: {
      return { ...state, activities: action.payload }
    }
    case ActionTypes.SetAppointmentTimes: {
      const appointmentTimes = action.payload ? appointmentTimesReducer(action.payload) : action.payload

      return { ...state, appointmentTimes }
    }
    case ActionTypes.SetAvailability: {
      const availability = action.payload ? availabilityReducer(action.payload) : action.payload

      return { ...state, availability }
    }
    case ActionTypes.SetVehicleInfo: {
      return { ...state, vehicleInfo: action.payload }
    }
    case ActionTypes.NextStep: {
      const step = state.step < 4 ? state.step + 1 : 4

      return { ...state, step }
    }
    case ActionTypes.PreviousStep: {
      const step = state.step > 0 ? state.step - 1 : 0

      return { ...state, step }
    }
    case ActionTypes.GoToStep: {
      return { ...state, step: action.payload }
    }
    case ActionTypes.ClearState: {
      return { ...defaultState }
    }
  }
}

const appointmentTimesReducer = (appointmentTimes: AppointmentTimesList): AppointmentTimes => {
  const initialValue: AppointmentTimes = {}

  return appointmentTimes.reduce((previousValue, currentValue) => {
    previousValue[currentValue.Date] = currentValue.Times

    return previousValue
  }, initialValue)
}

const availabilityReducer = (availability: AvailabilityList) => {
  return {
    days: mapToAvailabilityColorAndType(availability),
    maxDate: mapToMaxDate(availability),
    minDate: mapToMinDate(availability)
  }
}

const mapToMinDate = (availability: AvailabilityList): string => {
  return pipe(
    head(availability.Shops),
    map(shop => shop.Days),
    chain(head),
    fold(constant(''), item => item.Date)
  )
}

const mapToMaxDate = (availability: AvailabilityList): string => {
  return pipe(
    head(availability.Shops),
    map(shop => shop.Days),
    chain(last),
    fold(constant(''), item => item.Date)
  )
}

const mapToAvailabilityColorAndType = (availability: AvailabilityList): Availability['days'] => {
  const initialValue: Availability['days'] = {}

  return pipe(
    head(availability.Shops),
    fold(constant([]), shop => shop.Days),
    reduce(initialValue, (previous, current) => {
      previous[current.Date] = {
        availability: current.LowestCapacity.Availability,
        color: current.LowestCapacity.AvailabilityColor
      }

      return previous
    })
  )
}
