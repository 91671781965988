import { DateTime, DateTimeFormatOptions } from 'luxon'
import { useConfig } from '@appointment-planner/config-provider'

type Format = 'time' | 'date'

const formats: { time: Partial<DateTimeFormatOptions>; date: Partial<DateTimeFormatOptions> } = {
  time: { hour: '2-digit', minute: '2-digit' },
  date: { year: 'numeric', day: '2-digit', month: '2-digit' }
}

const emptyDate = '0001-01-01'

export const useLuxonFormatter = (format: Format) => {
  const { Locale } = useConfig()

  const formatter = (input: string) => {
    if (input === emptyDate) return '-'

    const dateTime = DateTime.fromISO(input)
    if (dateTime.isValid) {
      return dateTime.toLocaleString({ ...formats[format] }, { locale: Locale })
    }

    return input
  }

  return formatter
}
