import React from 'react'
import { ServiceContext } from '@appointment-planner/service-provider/ServiceContext'
import { Services } from '@appointment-planner/service-provider/services'

interface UseService {
  <S extends keyof Services>(service: S): Services[S]
  (service?: undefined): Services
}

export const useService: UseService = <S extends keyof Services>(service: S) => {
  const serviceContext = React.useContext(ServiceContext)

  if (service) return serviceContext[service]

  return serviceContext
}
