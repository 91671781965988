import React from 'react'
import { useTranslation } from 'react-i18next'
import { InputAdornment, TextField } from '@mui/material'
import { OdometerType } from '@carsys/enums/odometer-type'

export interface Props {
  error: undefined | string
  name: string
  onBlur: React.FocusEventHandler<HTMLInputElement>
  onChange: React.ChangeEventHandler<HTMLInputElement>
  required: boolean
  touched: undefined | boolean
  value: number
  odometerUnit: OdometerType
}

const Odometer = ({ error, name, odometerUnit, onBlur, onChange, required, touched, value }: Props) => {
  const { t } = useTranslation()

  const isError = Boolean(error && touched)

  return (
    <TextField
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      name={name}
      fullWidth
      required={required}
      error={isError}
      autoComplete='off'
      id='odometer'
      helperText={t('Planner.Fields.Odometer.Helper')}
      label={t('Planner.Fields.Odometer.Label')}
      InputProps={{
        endAdornment: <InputAdornment position='end'>{t('Planner.Fields.Odometer.Unit', { context: odometerUnit })}</InputAdornment>,
        inputProps: {
          'data-testid': 'odometer-input'
        }
      }}
      type='number'
    />
  )
}

Odometer.defaultProps = {
  required: true
}

export default Odometer
