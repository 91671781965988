import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import LuxonUtils from '@date-io/luxon'

import { useConfig } from '@appointment-planner/config-provider'

export const DatePickerUtilsProvider: React.FC = props => {
  const { Locale } = useConfig()

  return (
    <LocalizationProvider dateAdapter={LuxonUtils} adapterLocale={Locale}>
      {props.children}
    </LocalizationProvider>
  )
}
