export const fetchXDomain = async () => {
  const response = await fetch(window.location.origin, { method: 'HEAD' })
  const XDomainHeader = response.headers.get('x-domain')

  if (!XDomainHeader) {
    console.error('failed to load x-domain')

    return ''
  }

  return XDomainHeader
}
