import { Error, ErrorType, HTTPClient, HTTPClientConfig, HTTPClientConstructor, RequestConfig } from './interfaces'
import { Either, left, right } from 'fp-ts/lib/Either'
import { ValidationErrors } from '@appointment-planner/api/types/validation-error'

interface JSONWithErrorText {
  Error: string
}

interface JSONWithValidationErrors {
  ValidationErrors: ValidationErrors
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FetchClient: HTTPClientConstructor = class FetchClient implements HTTPClient {
  private readonly cache?: RequestCache
  private readonly credentials?: RequestCredentials
  private readonly headers?: Record<string, string> = {}
  private readonly mode?: RequestMode
  private readonly origin: string
  private readonly params?: Record<string, string> = {}
  private readonly redirect?: RequestRedirect
  private readonly referrer?: string
  private readonly referrerPolicy?: ReferrerPolicy

  constructor({ cache, credentials, headers, mode, origin, params, redirect, referrer, referrerPolicy }: HTTPClientConfig) {
    this.cache = cache
    this.credentials = credentials
    this.headers = headers
    this.mode = mode
    this.origin = origin
    this.params = params
    this.redirect = redirect
    this.referrer = referrer
    this.referrerPolicy = referrerPolicy
  }

  private buildRequestConfig({ cache, credentials, headers, mode, params, redirect, referrer, referrerPolicy, ...rest }: RequestConfig) {
    const config: RequestConfig = {
      ...rest,
      cache: cache || this.cache,
      credentials: credentials || this.credentials,
      headers: { ...this.headers, ...headers },
      mode: mode || this.mode,
      params: { ...this.params, ...params },
      redirect: redirect || this.redirect,
      referrer: referrer || this.referrer,
      referrerPolicy: referrerPolicy || this.referrerPolicy
    }

    const initialValue: RequestConfig = {}

    return Object.entries(config).reduce((previousValue, [currentKey, currentValue]) => {
      const key = currentKey as keyof RequestConfig

      if (currentValue) {
        previousValue[key] = currentValue
      }

      return previousValue
    }, initialValue)
  }

  private buildRequestURL(path: string, params?: Record<string, string>): string {
    const url = new URL(this.origin)

    url.pathname = url.pathname.concat(path).replace('//', '/')

    if (params) {
      Object.entries(params).forEach(([name, value]) => {
        if (Array.isArray(value)) {
          // array should add their values as separate entries
          value.forEach(arrayValue => url.searchParams.append(name, arrayValue))
        } else {
          url.searchParams.append(name, value)
        }
      })
    }

    return url.href
  }

  private isJSONParsable(input: string) {
    try {
      JSON.parse(input)

      return true
    } catch {
      return false
    }
  }

  private isObjectWithProperty<TProperty extends string>(obj: any, property: TProperty): obj is { [index in TProperty]: any } {
    return typeof obj === 'object' && obj !== null && property in obj
  }

  private isJSONTextError(error: unknown): error is JSONWithErrorText {
    return this.isObjectWithProperty(error, 'Error') && typeof error.Error === 'string'
  }

  private isJSONValidationError(error: unknown): error is JSONWithValidationErrors {
    return this.isObjectWithProperty(error, 'ValidationErrors') && Array.isArray(error.ValidationErrors)
  }

  private isNetworkError(error: unknown): error is TypeError {
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#Checking_that_the_fetch_was_successful
    // fetch will only reject the promise when there's a network error which will be
    // an instance of TypeError.
    return error instanceof TypeError
  }

  async doRequest(path: string, requestConfig: RequestConfig = {}): Promise<Either<Error, unknown>> {
    const { params, ...config } = this.buildRequestConfig(requestConfig)
    const url = this.buildRequestURL(path, params)

    try {
      const response = await fetch(url, config)

      if (!response.ok) {
        const text = await response.text()

        if (!this.isJSONParsable(text)) {
          return left({
            code: response.status,
            type: ErrorType.TextError,
            message: text
          })
        }

        const json: unknown = JSON.parse(text)

        if (this.isJSONTextError(json)) {
          return left({
            code: response.status,
            type: ErrorType.TextError,
            message: json.Error
          })
        }

        if (this.isJSONValidationError(json)) {
          return left({
            code: response.status,
            type: ErrorType.ValidationError,
            message: json.ValidationErrors
          })
        }

        return left({ type: ErrorType.UnknownError })
      }

      const json: unknown = await response.json()

      return right(json)
    } catch (error) {
      if (this.isNetworkError(error)) {
        return left({ type: ErrorType.NetworkError })
      }

      return left({ type: ErrorType.UnknownError })
    }
  }
}

export default FetchClient
