import React from 'react'
import { DateTime } from 'luxon'
import { useConfig } from '@appointment-planner/config-provider'

const Time: React.FC<{ value: string }> = ({ value }) => {
  const { Locale } = useConfig()
  const [hour, minute] = value.split(':')

  return (
    <>
      {DateTime.local()
        .set({ hour: parseInt(hour), minute: parseInt(minute) })
        .setLocale(Locale)
        .toLocaleString(DateTime.TIME_SIMPLE)}
    </>
  )
}

export default Time
