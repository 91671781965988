import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Field, FieldProps } from 'formik'

import { CountryCode } from '@carsys/enums/country-code'

import { useConfig } from '@appointment-planner/config-provider'

type NamePrefixByCountry = {
  [index in CountryCode]?: boolean
}

const isNamePrefixEnabledByCountry: NamePrefixByCountry = {
  [CountryCode.GB]: false,
  [CountryCode.NL]: true
}

const Step3 = () => {
  const { t } = useTranslation()
  const titleOptions = t<string, { returnObjects: true }, string[]>('Planner.Fields.Title.Values', { returnObjects: true })

  const { ShopCountryCode } = useConfig()
  const isNamePrefixEnabled = isNamePrefixEnabledByCountry[ShopCountryCode] ?? true

  return (
    <Grid container spacing={3}>
      <Grid item xs={5} sm={3}>
        <Field name='Title'>
          {({ field }: FieldProps<string>) => (
            <FormControl fullWidth>
              <InputLabel>{t('Planner.Fields.Title.Label')}</InputLabel>
              <Select {...field} label={t('Planner.Fields.Title.Label')}>
                {titleOptions.map(option => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Field>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm>
            <Field name='FirstName'>
              {({ field, meta }: FieldProps<string>) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t('Planner.Fields.FirstName.Label')}
                  error={Boolean(meta.touched && meta.error)}
                  inputProps={{
                    maxLength: 25
                  }}
                />
              )}
            </Field>
          </Grid>
          {isNamePrefixEnabled && (
            <Grid item xs={5} sm={3}>
              <Field name='NamePrefix'>
                {({ field, meta }: FieldProps<string>) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={t('Planner.Fields.NamePrefix.Label')}
                    error={Boolean(meta.touched && meta.error)}
                    inputProps={{
                      maxLength: 25
                    }}
                  />
                )}
              </Field>
            </Grid>
          )}
          <Grid item xs={12} sm>
            <Field name='Name'>
              {({ field, meta }: FieldProps<string>) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('Planner.Fields.Name.Label')}
                  required
                  error={Boolean(meta.touched && meta.error)}
                  inputProps={{
                    maxLength: 50
                  }}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm>
            <Field name='EmailAddress'>
              {({ field, meta }: FieldProps<string>) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t('Planner.Fields.EmailAddress.Label')}
                  error={Boolean(meta.touched && meta.error)}
                  inputProps={{
                    maxLength: 255
                  }}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} sm>
            <Field name='PhoneNumber'>
              {({ field, meta }: FieldProps<string>) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label={t('Planner.Fields.PhoneNumber.Label')}
                  error={Boolean(meta.touched && meta.error)}
                  inputProps={{
                    maxLength: 25
                  }}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={12} sm>
          <Field name='PostalCode'>
            {({ field, meta }: FieldProps<string>) => (
              <TextField
                {...field}
                fullWidth
                label={t('Planner.Fields.PostalCode.Label')}
                error={Boolean(meta.touched && meta.error)}
                inputProps={{
                  maxLength: 10
                }}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Field name='HouseNumber'>
            {({ field, meta }: FieldProps<string>) => (
              <TextField
                {...field}
                fullWidth
                label={t('Planner.Fields.HouseNumber.Label')}
                error={Boolean(meta.touched && meta.error)}
                inputProps={{
                  maxLength: 10
                }}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Field name='HouseNumberAddition'>
            {({ field, meta }: FieldProps<string>) => (
              <TextField
                {...field}
                fullWidth
                label={t('Planner.Fields.HouseNumberAddition.Label')}
                error={Boolean(meta.touched && meta.error)}
                inputProps={{
                  maxLength: 10
                }}
              />
            )}
          </Field>
        </Grid>
      </Grid>
      <Grid container item spacing={3}>
        <Grid item xs={6}>
          <Field name='Street'>
            {({ field, meta }: FieldProps<string>) => (
              <TextField
                {...field}
                fullWidth
                label={t('Planner.Fields.Street.Label')}
                error={Boolean(meta.touched && meta.error)}
                inputProps={{
                  maxLength: 50
                }}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name='City'>
            {({ field, meta }: FieldProps<string>) => (
              <TextField
                {...field}
                fullWidth
                label={t('Planner.Fields.City.Label')}
                error={Boolean(meta.touched && meta.error)}
                inputProps={{
                  maxLength: 50
                }}
              />
            )}
          </Field>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Field name='Remark'>
          {({ field, meta }: FieldProps<string>) => (
            <TextField
              {...field}
              multiline
              fullWidth
              helperText={t('Planner.Fields.Remark.Helper')}
              label={t('Planner.Fields.Remark.Label')}
              error={Boolean(meta.touched && meta.error)}
              inputProps={{
                maxLength: 500
              }}
            />
          )}
        </Field>
      </Grid>
    </Grid>
  )
}

export default Step3
