import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { SnackbarProvider } from 'notistack'

import { ThemeProvider } from '@appointment-planner/contexts/Theme'
import { DatePickerUtilsProvider } from '@appointment-planner/contexts/DatePickerUtils'
import { ServiceProvider } from '@appointment-planner/service-provider'
import { ConfigProvider } from '@appointment-planner/config-provider'
import i18n from '@appointment-planner/localization/i18n'
import ConfigErrorMessage from '@appointment-planner/components/ConfigErrorMessage'

export const AppInitProvider: React.FC = props => {
  return (
    <React.Suspense fallback={null}>
      <ConfigProvider>
        <I18nextProvider i18n={i18n}>
          <DatePickerUtilsProvider>
            <ThemeProvider>
              <SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                <ConfigErrorMessage />
                <ServiceProvider>{props.children}</ServiceProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </DatePickerUtilsProvider>
        </I18nextProvider>
      </ConfigProvider>
    </React.Suspense>
  )
}
