import { Either } from 'fp-ts/lib/Either'
import { ValidationErrors } from '@appointment-planner/api/types/validation-error'

export enum ErrorType {
  NetworkError = 'network_error',
  TextError = 'text_error',
  ValidationError = 'validation_error',
  UnknownError = 'unknown_error'
}
interface NetworkError {
  type: ErrorType.NetworkError
}

interface TextError {
  code: number
  type: ErrorType.TextError
  message: string
}

interface ValidationError {
  code: number
  type: ErrorType.ValidationError
  message: ValidationErrors
}

interface UnknownError {
  type: ErrorType.UnknownError
}

export type Error = NetworkError | TextError | ValidationError | UnknownError

export interface HTTPClientConfig extends Omit<RequestInit, 'body' | 'integrity' | 'keepalive' | 'method' | 'signal' | 'window'> {
  headers?: Record<string, string>
  params?: Record<string, string>
  origin: string
}

export interface RequestConfig extends RequestInit {
  headers?: Record<string, string>
  params?: Record<string, string>
}

export interface HTTPClient {
  doRequest(path: string, config?: RequestConfig): Promise<Either<Error, unknown>>
}

export interface HTTPClientConstructor {
  new (config: HTTPClientConfig): HTTPClient
}
