import React from 'react'
import { Grid } from '@mui/material'
import LicensePlate from '@appointment-planner/components/LicensePlate'
import Odometer from '@appointment-planner/components/Odometer'
import VehicleInformation from '@appointment-planner/components/VehicleInformation'
import { Vehicle } from '@appointment-planner/vehicles/models'

interface Props {
  licenseplateFormField: Pick<React.ComponentProps<typeof LicensePlate>, 'value' | 'name' | 'onChange' | 'onBlur' | 'error' | 'touched'>
  odometerFormField: Pick<
    React.ComponentProps<typeof Odometer>,
    'error' | 'name' | 'onBlur' | 'onChange' | 'touched' | 'value' | 'odometerUnit'
  >
  vehicleInfo: Vehicle | null
  validLicenseplate: boolean
}

const Step1: React.FC<Props> = ({ validLicenseplate, licenseplateFormField, odometerFormField, vehicleInfo }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <LicensePlate {...licenseplateFormField} showValidIcon={validLicenseplate} />
      </Grid>
      <Grid item xs={12}>
        <Odometer {...odometerFormField} />
      </Grid>
      <Grid item xs={12}>
        <VehicleInformation vehicleInfo={vehicleInfo} />
      </Grid>
    </Grid>
  )
}

export default Step1
