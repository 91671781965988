import React from 'react'
import { MenuItem, TextField } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { FormikHandlers } from 'formik'

import ClockIcon from './ClockIcon'
import Time from './Time'

interface TimeSlotProps {
  appointmentTimes: string[]
  error: string | undefined
  name: string
  onBlur: FormikHandlers['handleBlur']
  onChange: FormikHandlers['handleChange']
  touched: boolean
  value: string
}

const TimeSlots: React.FC<TimeSlotProps> = ({ appointmentTimes, error, name, onBlur, onChange, value, touched }) => {
  const { t } = useTranslation()

  return (
    <TextField
      select
      label={t('Planner.Fields.Time.Label')}
      error={Boolean(touched && error)}
      required
      margin='normal'
      variant='outlined'
      name={name}
      fullWidth
      onBlur={event => {
        event.target.name = name
        onBlur(event)
      }}
      onChange={onChange}
      value={value}
      SelectProps={{
        IconComponent: ClockIcon
      }}
    >
      {appointmentTimes.map(time => (
        <MenuItem key={time} value={time}>
          <Time value={time} />
        </MenuItem>
      ))}
    </TextField>
  )
}

export default React.memo(TimeSlots)
