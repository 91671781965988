import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Grid } from '@mui/material'
import { Loader } from '@carsys/ui'

interface Props {
  activeStep: number
  numberOfSteps: number
  disableNextButton: boolean
  handleStepBack: () => void
  handleStepNext: () => void
  handleSubmit: () => Promise<void>
  isSubmitting: boolean
}

const StepperButtons: React.FC<Props> = ({
  activeStep,
  disableNextButton,
  handleStepBack,
  handleStepNext,
  handleSubmit,
  isSubmitting,
  numberOfSteps
}) => {
  const { t } = useTranslation()

  const isSubmissionStep = activeStep === numberOfSteps - 1

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Button data-testid='back-button' disabled={activeStep === 0} fullWidth onClick={handleStepBack}>
          {t(`Planner.Buttons.Back`)}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          color='primary'
          data-testid='next-button'
          disabled={disableNextButton || isSubmitting}
          fullWidth
          onClick={isSubmissionStep ? handleSubmit : handleStepNext}
          variant='contained'
        >
          {isSubmitting ? <Loader loading size={24} /> : t(`Planner.Buttons.${isSubmissionStep ? 'Finish' : 'Next'}`)}
        </Button>
      </Grid>
    </Grid>
  )
}

export default StepperButtons
