import React from 'react'
import FetchClient from '@appointment-planner/api/client/fetch-client'
import { Services, createServices } from '@appointment-planner/service-provider/services'
import { useConfig } from '@appointment-planner/config-provider'

export const ServiceContext = React.createContext<Services>({} as any)

export const ServiceProvider: React.FC = props => {
  const { ShopCountryCode, Locale, ShopID, XDomain } = useConfig()

  const headers: Record<string, string> = {
    'Accept-Language': Locale,
    CountryCode: ShopCountryCode
  }

  if (__API_KEY__) {
    headers['X-API-KEY'] = __API_KEY__
  }

  const services = React.useMemo(() => {
    const client = new FetchClient({
      origin: XDomain,
      headers
    })

    const services = createServices(client)

    return services
  }, [ShopCountryCode, Locale, ShopID, XDomain])

  return <ServiceContext.Provider value={services}>{props.children}</ServiceContext.Provider>
}
