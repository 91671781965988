import { ActivitiesList } from './models'
import Service from '@appointment-planner/api/services/abstract-service'
import { ShopID } from '@appointment-planner/api/types'

export interface IActivitiesService {
  get(shopIDs: ShopID | ShopID[]): Promise<ActivitiesList | null>
}

export class ActivitiesService extends Service implements IActivitiesService {
  protected path = '/activities'

  async get(shopIDs: ShopID | ShopID[]): Promise<ActivitiesList | null> {
    const ShopIDs = this.stringifyShopIDs(shopIDs)
    const response = await this.client.doRequest(this.path, { params: { ShopIDs } })

    return this.decodeResponseTo(response, ActivitiesList)
  }
}
