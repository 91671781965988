import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Step, StepContent, StepLabel, Stepper } from '@mui/material'

import { Vehicle } from '@appointment-planner/vehicles/models'
import { Activity } from '@appointment-planner/activities/models'

import { ActionTypes, Actions, AppointmentTimes, Availability } from '@appointment-planner/state/applicationReducer'

import PlannerHeader from '@appointment-planner/components/PlannerHeader'
import PlannerButtons from '@appointment-planner/components/vertical/StepperButtons'
import FinalStep from '@appointment-planner/components/vertical/FinalStep'
import Step1Container from '@appointment-planner/containers/vertical/Step1Container'
import Step2Container from '@appointment-planner/containers/vertical/Step2Container'
import Step3Container from '@appointment-planner/containers/vertical/Step3Container'
import Step4Container from '@appointment-planner/containers/vertical/Step4Container'

export interface StepContentProps {
  activities: Activity[] | null
  appointmentTimes: AppointmentTimes | null
  availability: Availability | null
  step: number
  vehicleInfo: Vehicle | null
  dispatch: React.Dispatch<Actions>
}

interface VerticalPlannerProps {
  activeStep: number
  activities: Activity[] | null
  appointmentTimes: AppointmentTimes | null
  availability: Availability | null
  disableNextButton: boolean
  dispatch: React.Dispatch<Actions>
  handleReset: () => void
  handleSubmit: () => Promise<void>
  isSubmitting: boolean
  steps: string[]
  vehicleInfo: Vehicle | null
}

const GetStepContent: React.FC<StepContentProps> = ({ activities, appointmentTimes, availability, dispatch, step, vehicleInfo }) => {
  switch (step) {
    case 0:
      return <Step1Container vehicleInfo={vehicleInfo} dispatch={dispatch} />
    case 1:
      return <Step2Container activities={activities} appointmentTimes={appointmentTimes} availability={availability} dispatch={dispatch} />
    case 2:
      return <Step3Container />
    case 3:
      return <Step4Container vehicleInfo={vehicleInfo} activities={activities} />
    default:
      return null
  }
}

const VerticalPlanner: React.FC<VerticalPlannerProps> = ({
  activeStep,
  appointmentTimes,
  activities,
  availability,
  dispatch,
  disableNextButton,
  handleReset,
  handleSubmit,
  isSubmitting,
  steps,
  vehicleInfo
}) => {
  const { t } = useTranslation()

  const isLastStep = activeStep === steps.length

  return (
    <Box maxWidth={768} m='0 auto'>
      <PlannerHeader />
      <Stepper activeStep={activeStep} orientation='vertical'>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{t(label)}</StepLabel>
            <StepContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <GetStepContent
                    step={index}
                    vehicleInfo={vehicleInfo}
                    appointmentTimes={appointmentTimes}
                    availability={availability}
                    activities={activities}
                    dispatch={dispatch}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PlannerButtons
                    activeStep={activeStep}
                    disableNextButton={disableNextButton}
                    handleStepBack={() => dispatch({ type: ActionTypes.PreviousStep })}
                    handleStepNext={() => dispatch({ type: ActionTypes.NextStep })}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    numberOfSteps={steps.length}
                  />
                </Grid>
              </Grid>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {isLastStep && <FinalStep handleStepReset={handleReset} />}
    </Box>
  )
}

export default React.memo(VerticalPlanner)
