import React from 'react'
import i18next from 'i18next'
import { Settings } from 'luxon'
import { pipe } from 'fp-ts/lib/function'
import * as Sentry from '@sentry/browser'

import { countryCode, countryCodeFromShopID, locale, shopID } from '@appointment-planner/api/types'

import { fetchXDomain } from './fetchXDomain'

export const ConfigContext = React.createContext({
  ShopCountryCode: countryCode(''),
  ShopID: shopID(''),
  Locale: locale(''),
  XDomain: ''
})

export const ConfigProvider: React.FC = props => {
  const params = new URLSearchParams(window.location.search)
  const [XDomain, setXDomain] = React.useState('')

  React.useEffect(() => {
    // If this request fails the application is allowed to crash
    // since without this the application won't work anyway.
    fetchXDomain().then(setXDomain)
  }, [])

  const Locale = pipe(params.get('Locale'), locale)

  const ShopID = pipe(params.get('ShopID'), shopID)

  const ShopCountryCode = countryCodeFromShopID(ShopID)

  React.useLayoutEffect(() => {
    Settings.defaultLocale = Locale

    i18next.changeLanguage(Locale)
  }, [Locale])

  React.useEffect(() => {
    Sentry.configureScope(scope => {
      scope.setUser({
        id: ShopID,
        locale: Locale
      })
    })

    return () => {
      Sentry.configureScope(scope => scope.clear())
    }
  }, [Locale, ShopID])

  const value = React.useMemo(() => {
    return { ShopCountryCode, Locale, ShopID, XDomain }
  }, [ShopCountryCode, Locale, ShopID, XDomain])

  return XDomain ? <ConfigContext.Provider value={value}>{props.children}</ConfigContext.Provider> : null
}
