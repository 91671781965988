import * as t from 'io-ts'
import { CountryCodeRTT } from '@appointment-planner/api/types/country-code'

export const Vehicle = t.interface(
  {
    Brand: t.string,
    Model: t.string,
    Registration: t.string,
    RegistrationCountry: CountryCodeRTT,
    Type: t.string,
    MOTExpiryDate: t.string
  },
  'Vehicle'
)

export type Vehicle = t.TypeOf<typeof Vehicle>
