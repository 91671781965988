import * as t from 'io-ts'

const AppointmentTimes = t.interface(
  {
    Date: t.string,
    Times: t.array(t.string)
  },
  'AppointmentTimes'
)

export const AppointmentTimesList = t.array(AppointmentTimes, 'AppointmentTimesList')

export type AppointmentTimesList = t.TypeOf<typeof AppointmentTimesList>
