import { BackendModule, ReadCallback } from 'i18next'

export class Backend implements Pick<BackendModule, 'type' | 'read'> {
  static type = 'backend'
  readonly type = 'backend' // this is required by the typedef

  async read(language: string, _namespace: string, callback: ReadCallback) {
    try {
      const translations = await import(`localization/translations/${language}.json`)
      callback(null, translations)

      return
    } catch (e) {
      console.error(e)
    }

    // in case the language can't be found
    callback(null, {})
  }
}

export default Backend
