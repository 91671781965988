import React from 'react'
import { Divider, List, Paper } from '@mui/material'

import ActivityCard, { Activity } from './ActivityCard'

interface ActivityCardProps extends Pick<React.ComponentProps<typeof ActivityCard>, 'onClick'> {
  activities: Activity[] | null
}

const ActivityCardList: React.FC<ActivityCardProps> = ({ activities, onClick }) => {
  if (!activities || activities.length < 1) {
    return null
  }

  return (
    <List component={Paper} data-testid='activity-list'>
      {activities.map((activity, index) => (
        <React.Fragment key={activity.UUID}>
          <ActivityCard activity={activity} onClick={onClick} />
          {index !== activities.length - 1 && <Divider variant='fullWidth' component='li' />}
        </React.Fragment>
      ))}
    </List>
  )
}

export default ActivityCardList
