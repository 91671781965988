import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, FieldProps, useFormikContext } from 'formik'
import { FormControl, FormControlLabel, FormLabel, Grid, InputLabel, Radio, RadioGroup } from '@mui/material'
import { Availability } from '@appointment-planner/state/applicationReducer'
import ActivityCardList from '@appointment-planner/components/ActivityCardList'
import Calendar from '@appointment-planner/components/Calendar'
import TimeSlots from '@appointment-planner/components/TimeSlots'
import ActivityRemark from '@appointment-planner/components/ActivityRemark'
import ActivityCard from '@appointment-planner/components/ActivityCard'
import NoAvailabilityMessage from '@appointment-planner/components/NoAvailabilityMessage'
import { Loader } from '@carsys/ui'
import { useConfig } from '@appointment-planner/config-provider'
import { useService } from '@appointment-planner/service-provider'

export interface Step2Props
  extends Pick<React.ComponentProps<typeof ActivityCardList>, 'activities'>,
    Pick<React.ComponentProps<typeof ActivityCard>, 'onClick'> {
  availability: Availability | null
  appointmentTimes: string[]
  isShopOpen: boolean
  onClickCourtesyVehicle: (e: any) => void
}

const Step2: React.FC<Step2Props> = ({ activities, appointmentTimes, availability, isShopOpen, onClick, onClickCourtesyVehicle }) => {
  const { t } = useTranslation()
  const { ShopID } = useConfig()
  const { values } = useFormikContext<{ CourtesyVehicle: boolean | null }>()
  const [showCourtesyVehicle, setShowCourtesyVehicle] = useState(false)

  const shopDetailsService = useService('shopDetails')

  useEffect(() => {
    ;(async () => {
      const shopDetails = await shopDetailsService.get(ShopID)
      if (shopDetails) {
        setShowCourtesyVehicle(shopDetails.ModuleCourtesyVehiclesEnabled)
      }
    })()
  }, [])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <InputLabel required shrink>
          {t('Planner.Fields.Activities.Helper')}
        </InputLabel>
        <ActivityCardList activities={activities} onClick={onClick} />
      </Grid>
      <Grid item xs={12}>
        <ActivityRemark />
      </Grid>
      {availability ? (
        isShopOpen ? (
          <>
            <Grid item xs={12} container justifyContent='center'>
              <Grid item xs={12} sm={9}>
                <Field as={Calendar} name='Date' availability={availability} />
              </Grid>
              <Grid item xs={12} sm={9}>
                <Field name='Time'>
                  {({ field, meta }: FieldProps<string>) => (
                    <TimeSlots
                      appointmentTimes={appointmentTimes}
                      error={meta.error}
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      touched={meta.touched}
                      value={field.value}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            {showCourtesyVehicle && (
              <Grid paddingLeft={3} paddingTop={2.5}>
                <FormControl required data-testid='select-courtesy-vehicle'>
                  <FormLabel sx={{ color: 'black' }}>{t('Planner.Fields.CourtesyVehicle.Title')}</FormLabel>
                  <RadioGroup
                    row
                    name='CourtesyVehicle'
                    onChange={onClickCourtesyVehicle}
                    defaultValue={values.CourtesyVehicle !== null ? (values.CourtesyVehicle ? 'yes' : 'no') : null}
                  >
                    <FormControlLabel value='yes' control={<Radio />} label={t('Planner.Fields.CourtesyVehicle.Yes')} />
                    <FormControlLabel value='no' control={<Radio />} label={t('Planner.Fields.CourtesyVehicle.No')} />
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}
          </>
        ) : (
          <Grid item xs={12}>
            <NoAvailabilityMessage />
          </Grid>
        )
      ) : (
        <Grid container justifyContent='center'>
          <Loader loading />
        </Grid>
      )}
    </Grid>
  )
}

export default Step2
