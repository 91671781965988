import React from 'react'
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider, createTheme } from '@mui/material'
import { grey, red } from '@mui/material/colors'

export const ThemeProvider: React.FC = props => {
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: '#005596'
          },
          muted: {
            main: grey[400],
            dark: grey[600],
            light: grey[400],
            contrastText: '#333',
            backgroundHighlight: grey[200]
          },
          LKQGrey: {
            background: { modest: grey[600], highlight: grey[700], surface: grey[100], surfaceHover: grey[50] },
            border: { modest: 'rgba(0, 0, 0, 0.12)' }
          },
          primaryThemeColor: {
            main: 'rgb(8, 92, 240)',
            light: 'rgb(66, 135, 255)',
            dark: 'rgb(0, 31, 84)',
            extraDark: 'rgb(0, 16, 43)',
            contrastText: '#fff'
          }
        },
        components: {
          MuiFormLabel: {
            styleOverrides: {
              asterisk: {
                color: red[500],
                '&$error': {
                  color: red[500]
                }
              }
            }
          }
        }
      }),
    []
  )

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
    </StyledEngineProvider>
  )
}
