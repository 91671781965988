import React from 'react'
import { Card, CardContent, Grid, InputAdornment, InputBase, Typography } from '@mui/material'

import { CountryCode } from '@carsys/enums/country-code'

import CheckIcon from './CheckIcon'
import CancelIcon from './CancelIcon'
import EUStars from './EUStars.svg'
import { useConfig } from '@appointment-planner/config-provider'

interface LicensePlateProps {
  backgroundColor: string
  isEU: boolean
  textColor: string
  text: string
}

type PropsByCountry = {
  [index in CountryCode]?: LicensePlateProps
}

const propsByCountry: PropsByCountry = {
  [CountryCode.NL]: {
    backgroundColor: '#ffcb00',
    isEU: true,
    textColor: '#000',
    text: 'NL'
  },
  [CountryCode.GB]: {
    backgroundColor: '#fff',
    isEU: false,
    textColor: '#000',
    text: 'GB'
  },
  [CountryCode.BE]: {
    backgroundColor: '#fff',
    isEU: true,
    textColor: '#9b111e',
    text: 'B'
  }
}

export interface Props {
  value: string
  name: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onBlur: React.FocusEventHandler<HTMLInputElement>
  error: undefined | string
  touched: undefined | boolean
  showValidIcon: boolean
}

const Licenseplate: React.FC<Props> = ({ showValidIcon, error, touched, value, name, onChange, onBlur }) => {
  const { ShopCountryCode } = useConfig()

  const licensePlateProps = propsByCountry[ShopCountryCode]
  const countryText = licensePlateProps?.text
  const isEU = licensePlateProps?.isEU

  return (
    <Card sx={{ backgroundColor: licensePlateProps?.backgroundColor, height: 60, minWidth: 170 }}>
      <CardContent
        sx={{
          height: 60,
          padding: 0,
          '&:last-child': {
            paddingBottom: 0
          }
        }}
      >
        <Grid container direction='row' spacing={0} alignItems='center'>
          <Grid
            item
            xs={3}
            sm={2}
            md={1}
            sx={{
              alignItems: 'center',
              backgroundColor: '#2c81bc',
              display: 'flex',
              flexDirection: 'column',
              height: 60,
              justifyContent: 'flex-end',
              maxWidth: 60,
              padding: 1,
              textAlign: 'center'
            }}
          >
            {countryText && (
              <>
                {isEU && <EUStars width={25} height={25} />}
                <Typography sx={{ fontSize: 14, color: 'common.white' }}>{countryText}</Typography>
              </>
            )}
          </Grid>
          <Grid item xs={9} sm={10} md={11}>
            <InputBase
              value={value}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              fullWidth
              required
              autoComplete='off'
              id='licenseplate'
              type='text'
              sx={{
                paddingLeft: 1,
                paddingRight: 1,
                fontSize: '2em'
              }}
              inputProps={{
                'data-testid': 'registration-input',
                sx: { color: licensePlateProps?.textColor, textTransform: 'uppercase', textAlign: 'center' },
                maxLength: 12
              }}
              endAdornment={
                <InputAdornment position='end'>
                  {showValidIcon && <CheckIcon showIcon={showValidIcon} />}
                  {Boolean(error && touched) && <CancelIcon showIcon={Boolean(error && touched)} />}
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Licenseplate
