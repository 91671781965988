import * as t from 'io-ts'
import { pipe } from 'fp-ts/lib/function'
import { getOrElse } from 'fp-ts/lib/Either'

export interface ShopIDBrand {
  readonly ShopID: unique symbol
}

export const ShopIDRegEx = /^[a-z]{2}[0-9]{6}$/i

export const ShopID = t.brand(
  t.string,
  (s: string): s is t.Branded<string, ShopIDBrand> => {
    return ShopIDRegEx.test(s)
  },
  'ShopID'
)

export const shopID = (s: unknown) => {
  return pipe(
    ShopID.decode(s),
    getOrElse(() => '' as ShopID)
  )
}

export type ShopID = t.TypeOf<typeof ShopID>
