import * as t from 'io-ts'
import { ShopID } from '@appointment-planner/api/types'

export const ShopDetails = t.interface(
  {
    ID: ShopID,
    ModuleCourtesyVehiclesEnabled: t.boolean
  },
  'ShopDetails'
)

export type ShopDetails = t.TypeOf<typeof ShopDetails>
