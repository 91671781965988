import { AvailabilityList } from './models'
import Service from '@appointment-planner/api/services/abstract-service'
import { ShopID, UUID } from '@appointment-planner/api/types'

export interface IAvailabilityService {
  get(shopIDs: ShopID | ShopID[], ActivityUUIDs: UUID[]): Promise<AvailabilityList | null>
}

export class AvailabilityService extends Service implements IAvailabilityService {
  protected path = '/availability'

  async get(shopIDs: ShopID | ShopID[], activityUUIDs: UUID[]): Promise<AvailabilityList | null> {
    const ActivityUUIDs = activityUUIDs.join(',')
    const ShopIDs = this.stringifyShopIDs(shopIDs)
    const response = await this.client.doRequest(this.path, { params: { ActivityUUIDs, ShopIDs } })

    return this.decodeResponseTo(response, AvailabilityList)
  }
}
