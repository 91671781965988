import Service from '@appointment-planner/api/services/abstract-service'
import { ShopDetails } from './models'
import { ShopID } from '@appointment-planner/api/types'

export interface IShopDetailsService {
  get(shopID: ShopID): Promise<ShopDetails | null>
}

export class ShopDetailsService extends Service implements IShopDetailsService {
  protected path = `/shops`

  async get(shopID: ShopID): Promise<ShopDetails | null> {
    const result = await this.client.doRequest(this.path, { params: { ShopID: shopID } })

    return this.decodeResponseTo(result, ShopDetails)
  }
}
