import CheckCircle from '@mui/icons-material/CheckCircle'
import { Zoom } from '@mui/material'

interface Props {
  showIcon: boolean
}

const CheckIcon = ({ showIcon }: Props) => {
  return (
    <Zoom in={showIcon}>
      <CheckCircle
        data-testid='valid-registration-icon'
        sx={{
          color: 'primary.dark'
        }}
      />
    </Zoom>
  )
}

CheckIcon.defaultProps = {
  showIcon: true
}

export default CheckIcon
