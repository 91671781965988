import * as t from 'io-ts'
import { pipe } from 'fp-ts/lib/function'
import { getOrElse } from 'fp-ts/lib/Either'

interface LocaleBrand {
  readonly Locale: unique symbol
}

const LocaleRegEx = /^[a-z]{2}-[A-Z]{2}$/

export const Locale = t.brand(
  t.string,
  (s: string): s is t.Branded<string, LocaleBrand> => {
    return LocaleRegEx.test(s)
  },
  'Locale'
)

export const locale = (s: unknown) => {
  return pipe(
    Locale.decode(s),
    getOrElse(() => '' as Locale)
  )
}

export type Locale = t.TypeOf<typeof Locale>
