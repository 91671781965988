import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, InputLabel, TextField, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { MobileDatePicker, StaticDatePicker } from '@mui/x-date-pickers'
import { BaseToolbarProps } from '@mui/x-date-pickers/internals'
import { DateTime } from 'luxon'
import { constant, identity, pipe } from 'fp-ts/lib/function'
import { fold, fromNullable, map } from 'fp-ts/lib/Option'

import createCustomPickerDayProps from '@appointment-planner/helpers/createCustomDatePickerDayProps'
import { Availability } from '@appointment-planner/state/applicationReducer'

interface Props {
  availability: Availability | null
  onChange: (name: string) => (value: string) => void
  name: string
  value: string
}

function determineValue(value: string | null, availability: Availability | null) {
  if (!value) {
    return pipe(
      fromNullable(availability),
      map(availability => availability.minDate),
      map(minDate => DateTime.fromISO(minDate)),
      fold(constant(null), identity)
    )
  }

  return pipe(
    fromNullable(value),
    map(date => DateTime.fromISO(date)),
    fold(constant(null), identity)
  )
}

const Calendar: React.FC<Props> = ({ availability, name, value, ...props }) => {
  const { t } = useTranslation()
  const customDatePickerDayProps = React.useMemo(() => createCustomPickerDayProps(availability), [availability])
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  const onChange = React.useCallback(
    (date: DateTime | null) => {
      props.onChange(name)(date ? date.toSQLDate() : '')
    },
    [props.onChange, name]
  )

  return matches ? (
    <React.Fragment>
      <InputLabel required shrink>
        {t('Planner.Fields.ProposalDate.Helper')}
      </InputLabel>
      <StaticDatePicker
        componentsProps={{
          actionBar: {
            actions: []
          }
        }}
        data-testid='availability-datepicker'
        disableMaskedInput
        onChange={onChange}
        orientation='landscape'
        renderInput={params => <TextField {...params} fullWidth />}
        ToolbarComponent={CustomToolbar}
        value={determineValue(value, availability)}
        {...customDatePickerDayProps}
      />
    </React.Fragment>
  ) : (
    <MobileDatePicker
      data-testid='availability-datepicker'
      disableMaskedInput
      onChange={onChange}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          helperText={t('Planner.Fields.ProposalDate.Helper')}
          label={t('Planner.Fields.ProposalDate.Label')}
          required
        />
      )}
      ToolbarComponent={CustomToolbar}
      value={determineValue(value, availability)}
      {...customDatePickerDayProps}
    />
  )
}

export default React.memo(Calendar)

function CustomToolbar({ parsedValue, toolbarFormat = 'DDDD' }: BaseToolbarProps<DateTime, DateTime | null>) {
  return (
    <Box
      sx={{
        backgroundColor: 'primary.main',
        color: theme => theme.palette.getContrastText(theme.palette.primary.main),
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        p: 3
      }}
    >
      <Typography data-testid='selected-date' variant='h5'>
        {parsedValue ? parsedValue.toFormat(toolbarFormat) : '-'}
      </Typography>
    </Box>
  )
}
